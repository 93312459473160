import Client from "./clients/SecureWarehouseClient";

const resource = "/cockpit";

export default {
    async getStockInfo(warehouseIds, itemCnr, itemIndex, remainingStock, warehousePlaceShortName, hasStock, size, sort) {
        let queryArray = [];
        let queryStr = "";
        if (warehouseIds && warehouseIds.length > 0) {
            queryArray.push("warehouseIds=" + warehouseIds.join(","));
        }
        if (itemCnr) {
            queryArray.push("cnr=" + encodeURIComponent(itemCnr));
        }
        if (itemIndex) {
            queryArray.push("itemIndex=" + encodeURIComponent(itemIndex));
        }
        if (warehousePlaceShortName) {
            queryArray.push("warehousePlaceShortName="+encodeURIComponent(warehousePlaceShortName));
        }
        if (size) {
            queryArray.push("size="+size)
        }
        if (remainingStock) {
            queryArray.push("remainingStock="+encodeURIComponent(remainingStock))
        }
        if (hasStock) {
            queryArray.push("hasStock="+encodeURIComponent(hasStock))
        }
        if (sort) {
            queryArray.push("sort="+encodeURIComponent(sort))
        }
        if (queryArray.length !== 0) {
            queryStr += "?";
            queryStr += queryArray.join("&");
        }
        return Client.get(`${resource}/article${queryStr}`).then((response) => {
            return response.data;
        })
    },
};
