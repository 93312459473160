import Repository from "@/repositories/RepositoryFactory";

const GroupPickingRepository = Repository.get("groupPicking");

export default {
    namespaced: true,
    state: {
        pickingOrders: [],
        pickingOrderProposals: [],
        selectedWarehouse: {},
        defaultPickingOrderSort: [
            {shortName: "place", translateName: "sort.place"},
            {shortName: "articleNumber", translateName: "sort.articleNumber"},
        ],
        defaultPickingOrdersSort: [
            {shortName: "createDateTime", translateName: "sort.createDateTime"},
            {shortName: "orderNumber", translateName: "sort.orderNumber"},
            {shortName: "assemblyArticleNumber", translateName: "sort.assemblyArticleNumber"}
        ],
        previewPickingOrder: {},
        selectedPickingOrderId: null,
        isLoadingPickingOrders: false
    },
    getters: {
        selectedWarehouse(state) {
            return state.selectedWarehouse;
        },
        selectedPickingOrder(state) {
            const order = state.pickingOrders.find((o) => o.warehouseId == state.selectedWarehouse.id && o.id == state.selectedPickingOrderId);
            return order;
        },
        getPickingOrderSort(state) {
            return state.defaultPickingOrderSort;
        },
        getPickingOrdersSort(state) {
            return state.defaultPickingOrdersSort;
        },
        getPickingOrderById: (state) => (id) => {
            return state.pickingOrders.find(
              (o) =>
                  o.warehouseId == state.selectedWarehouse.id &&
                  o.id == id
          );
        },
        getPickingOrderByPickingOrderNumber: (state) => (pickingOrderNumber) => {
            return state.pickingOrders.find(
                (o) =>
                    o.n == pickingOrderNumber &&
                    o.warehouseId == state.selectedWarehouse.id
            );
        },
        getPickingOrdersByState: (state) => (orderState) => {
            if(!state.selectedWarehouse?.id) {
                return [];
            }
            let orders = state.pickingOrders
                .filter(
                    (o) => {
                        if (orderState === "CREATED") {
                            if(!o.pickingOrderState) {
                                return true;
                            }
                        }
                        //TODO empty state does not work yet
                        //TODO completed and deleted does not work yet
                        //TODO proper status update in backend
                        return o.warehouseId == state.selectedWarehouse.id
                            && orderState === o.pickingOrderState;
                    }
                );
            return orders;
        },
        getPickingOrders(state) {
            if(!state.selectedWarehouse?.id) {
                return [];
            }
            let orders = state.pickingOrders
                .filter(
                (o) => {
                    return o.warehouseId == state.selectedWarehouse.id;
                }
            );
            return orders;
        },
        hasNotEnoughStock: (state, getters) => (id) => {
            if (
                getters
                    .getPickingOrderById(id)
                    .e.find((item) => item.m >= item.inventory) == null
            ) {
                return false;
            } else {
                return true;
            }
        },
        orderPickingStarted: (state, getters) => (id) => {
            if (getters.getPickingOrderById(id)?.pickingOrderState === 'STARTED') {
                return true;
            } else {
                return false;
            }
        },
        getPickingOrderItem:
            (state, getters) =>
                ({id, index}) => {
                    return getters
                        .getPickingOrderById(id)
                        .e.find((item) => item.index == index);
                },
        getPreviewPickingOrder(state) {
            return state.previewPickingOrder;
        },
        hasReadPermission: (rootGetters, getters) => (id) => {
            const userId = id ? getters.getPickingOrderById(id)?.userId : null;
            const currentUserId = id ? rootGetters["auth/user"]?.id : null;
            const readOthers = rootGetters['auth/hasReadOthersPermission']('groupPicking');
            const read = rootGetters["auth/hasReadPermission"]('groupPicking');

            return userId === currentUserId ? read : readOthers;
        },
        hasWritePermission: (state, getters, rootState, rootGetters) => (id) => {
            const userId = id ? getters.getPickingOrderById(id)?.userId : null;
            const currentUserId = id ? rootGetters["auth/user"]?.id : null;
            const writeOthers = rootGetters["auth/hasWriteOthersPermission"]('groupPicking');
            const write = rootGetters["auth/hasWritePermission"]('groupPicking');

            return userId === currentUserId ? write : writeOthers;
        },
        isLoadingPickingOrders(state) {
            return state.isLoadingPickingOrders;
        }
    },
    mutations: {
        setWarehouse(state, warehouse) {
            state.selectedWarehouse = warehouse;
        },
        isLoadingPickingOrders(state, isLoading) {
            state.isLoadingPickingOrders = isLoading;
        },
        setPickingOrders(state, pickingOrders) {
          state.pickingOrders = pickingOrders;
        },
        addPickingOrder(state, pickingOrder) {
            state.pickingOrders.push(pickingOrder);
        },
        setPreviewPickingOrder(state, pickingOrder) {
            state.previewPickingOrder = pickingOrder;
        },
        resetPreviewPickingOrder(state) {
            state.previewPickingOrder = {};
        },
        addPickingOrderProposal(state, pickingOrderProposal) {
            let found = state.pickingOrderProposals.find((o) => {
                return (
                    o.warehouseId == state.selectedWarehouse.id &&
                    o.n == pickingOrderProposal.n
                );
            });
            if (found) {
                Object.assign(found, pickingOrderProposal);
            } else {
                state.pickingOrderProposals.push(pickingOrderProposal);
            }
        },
        replaceStore(state, initStore) {
            state.pickingOrders = initStore.pickingOrders;
            state.pickingOrderProposals = initStore.pickingOrderProposals;
            state.selectedWarehouse = initStore.selectedWarehouse;
        },
        updatePickingOrder(state, {id, pickingOrder}) {
            let found = state.pickingOrders.find(
                (o) =>
                    o.id == id &&
                    o.warehouseId == state.selectedWarehouse.id
            );
            Object.assign(found, pickingOrder);
        },
        setSelectedPickingOrder(state, id) {
            state.selectedPickingOrderId = id;
        },
        deletePickingOrderByPickingOrderNumber(state, {pickingOrderNumber}) {
            state.pickingOrders.splice(
                state.pickingOrders.findIndex(
                    (pickingOrder) =>
                        pickingOrder.n == pickingOrderNumber &&
                        pickingOrder.warehouseId == state.selectedWarehouse.id
                ),
                1
            );
        },
        deletePickingOrder(state, {id}) {
            state.pickingOrders.splice(
                state.pickingOrders.findIndex(
                    (pickingOrder) =>
                        pickingOrder.id == id &&
                        pickingOrder.warehouseId == state.selectedWarehouse.id
                ),
                1
            );
        },
        deletePickingOrderProposal(state, {pickingOrderNumber}) {
            state.pickingOrderProposals.splice(
                state.pickingOrderProposals.findIndex(
                    (proposal) =>
                        (proposal.n =
                            pickingOrderNumber && proposal.warehouseId == state.selectedWarehouse.id)
                ),
                1
            );
        },
        deletePickingOrderProposalByPickingOrderNumber(
            state,
            {pickingOrderNumber}
        ) {
            state.pickingOrderProposals.splice(
                state.pickingOrderProposals.findIndex(
                    (proposal) =>
                        proposal.n == pickingOrderNumber &&
                        proposal.warehouseId == state.selectedWarehouse.id
                ),
                1
            );
        },
        resetSelectedPickingOrder(state) {
            state.selectedPickingOrderId = null;
        }
    },
    actions: {
        //TODO delete me
        async sortPickingOrders({getters}, sortOrder) {
            getters.selectedWarehouse;
            console.log(sortOrder);
        },
        async loadPickingOrders({commit, getters, rootGetters}, sortOrder) {
            if(!getters.selectedWarehouse?.id) {
                return Promise.resolve();
            }
            let orderStates = ["CREATED", "STARTED", "COMPLETED", "CANCELED"];
            let userId = rootGetters['auth/user']?.id;

            if (rootGetters['auth/hasReadOthersPermission']('groupPicking') === true) {
                userId = null;
            }

            let sortBy = null;
            let sortDirection = null;

            if(sortOrder) {
                sortBy = sortOrder.sortBy;
                sortDirection = sortOrder.order;
            }

            commit("isLoadingPickingOrders", true);

            let warehouse = getters.selectedWarehouse?.id;
            return GroupPickingRepository
                .getPickingOrders(warehouse, userId, orderStates, sortBy, sortDirection)
                .then((response) => {
                    commit("setPickingOrders", response?.data);
                    commit("resetSelectedPickingOrder");
                    commit("isLoadingPickingOrders", false);
                }).catch(() => {
                    commit("isLoadingPickingOrders", false);
                });
        },
        async setWarehouse({commit, dispatch}, warehouse) {
            await commit("setWarehouse", warehouse);
            return await dispatch("loadPickingOrders");
        },
        async readPickingOrder({getters, commit, dispatch}, {amount, pickingOrder}) {
            pickingOrder.warehouseId = getters.selectedWarehouse.id;
            let multiplier = pickingOrder.amount ? pickingOrder.amount : 1;
            pickingOrder.amount = amount;
            pickingOrder.e = pickingOrder.e.map((item, index) => ({
                index: index,
                ...item,
                m: item.m * amount / multiplier
            }));
            if (!getters.getPickingOrderByPickingOrderNumber(pickingOrder.n)) {
                dispatch("addPickingOrder", pickingOrder);
            }
            commit('resetSelectedPickingOrder');
            commit('resetPreviewPickingOrder');
        },
        async updatePickingOrderAmount({getters, dispatch}, {newAmount, pickingOrder}) {
            pickingOrder.warehouseId = getters.selectedWarehouse.id;
            let oldAmount = pickingOrder.amount;
            pickingOrder.amount = newAmount;
            pickingOrder.e = pickingOrder.e.map((item, index) => ({
                index: index,
                ...item,
                m: item.m * newAmount / oldAmount
            }));

            return dispatch("updatePickingOrder", {id: pickingOrder.id, pickingOrder: pickingOrder});
        },
        async previewPickingOrder({getters, commit}, {amount, pickingOrder}) {
            pickingOrder.warehouseId = getters.selectedWarehouse.id;
            let multiplier = pickingOrder.amount ? pickingOrder.amount : 1;
            pickingOrder.amount = amount;
            pickingOrder.e = pickingOrder.e.map((item, index) => ({
                index: index,
                ...item,
                m: item.m * amount / multiplier
            }));

            return GroupPickingRepository.previewPickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder)
                .then((pickingOrderProposal) => {
                    pickingOrderProposal.e.sort((a, b) => a.availableAmount - b.availableAmount);
                    commit("setPreviewPickingOrder", pickingOrderProposal);
                })
        },
        async addPickingOrder({getters, commit}, pickingOrder) {
            return GroupPickingRepository.createPickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder)
                .then((pickingOrderResponse) => {
                    commit("addPickingOrder", pickingOrderResponse);
                })
        },
        async initialiseStore({commit}, initStore) {
            return commit("replaceStore", initStore);
        },
        async checkStock({getters, commit}, {id}) {
            let pickingOrder =
                getters.getPickingOrderById(id);
            return GroupPickingRepository.getPickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder
            ).then((pickingOrderProposal) => {
                pickingOrderProposal.e.forEach((pickingItem) => {
                    pickingItem.availableStockDetails = pickingItem.availableStockDetails.sort((a, b) =>
                        a.place.localeCompare(b.place)
                    );
                });
                pickingOrderProposal.e.sort((a, b) => a.availableAmount - b.availableAmount);
                commit("addPickingOrderProposal", pickingOrderProposal);
                commit("updatePickingOrder", {id: pickingOrder.id , pickingOrder: pickingOrderProposal });
                /*
                dispatch("addInventoryInformationToPickingOrder", {
                    pickingOrder,
                    pickingOrderProposal,
                });*/
            });
        },
        async sortPickingOrderBy(
            {getters, commit},
            {id, sortBy, order}
        ) {
            let pickingOrder = getters.getPickingOrderById(id);
            if (sortBy === "place") {
                pickingOrder.e = pickingOrder.e.sort((a, b) => {
                        let left = a.availableStockDetails[0]?.place;
                        let right = b.availableStockDetails[0]?.place;
                        if(!left) {
                            return -1;
                        }
                        if(!right) {
                            return 1;
                        }
                        return left.localeCompare(right)
                    }
                );
            }
            if (sortBy === "articleNumber") {
                pickingOrder.e = pickingOrder.e.sort((a, b) => a.t?.localeCompare(b.t));
            }
            if (order === "desc") {
                pickingOrder.e = pickingOrder.e.reverse();
            }
            commit("updatePickingOrder", {id: pickingOrder.id, pickingOrder});
        },
        async deletePickingOrderById({getters, commit}, id) {
            let pickingOrder = getters.getPickingOrderById(id);

            GroupPickingRepository.cancelPickingOrder(
                pickingOrder?.warehouseId,
                id
            ).then();

            commit("deletePickingOrderProposalByPickingOrderNumber", {
                pickingOrderNumber: pickingOrder.n,
            });
            commit("deletePickingOrderByPickingOrderNumber", {pickingOrderNumber: pickingOrder.n});
        },
        async finishPickingOrderByPickingOrderNumber({getters, commit}, id) {
            let pickingOrder = getters.getPickingOrderById(id);

            GroupPickingRepository.completePickingOrder(
                pickingOrder?.warehouseId,
                id
            ).then();

            commit("deletePickingOrderProposalByPickingOrderNumber", {
                pickingOrderNumber: pickingOrder.n,
            });
            commit("deletePickingOrderByPickingOrderNumber", {pickingOrderNumber: pickingOrder.n});
        },
        async loadPickingOrderWithStockInfo({getters, commit}, id) {
            if( id == getters.selectedPickingOrder?.id ) {
                return Promise.resolve();
            } else {
                commit("setSelectedPickingOrder", id);
                return GroupPickingRepository.getPickingOrderWithStockInfo(getters.selectedWarehouse.id, id)
                    .then((response) => {
                        let pickingOrder = response?.data;
                        if (pickingOrder) {
                            pickingOrder.e = pickingOrder.e.map((pickItem) => ({
                                ...pickItem,
                                picking: pickItem.picking || {
                                    pickedAmount: 0
                                }
                            }))
                            pickingOrder.e.forEach((pickingItem) => {
                                pickingItem.availableStockDetails = pickingItem.availableStockDetails.sort((a, b) =>
                                    a.place.localeCompare(b.place)
                                );
                            });
                            pickingOrder.e.sort((a, b) => a.availableAmount - b.availableAmount);
                        }
                        commit("updatePickingOrder", { id: id, pickingOrder: response?.data });
                    });
            }
        },
        async incrementPickedAmount(
            {getters, dispatch},
            {id, index, pickedAmount}
        ) {
            let pickingOrder =
                getters.getPickingOrderById(id);
            pickingOrder.e.find((item) => item.index == index).picking.pickedAmount +=
                pickedAmount;
            return dispatch("updatePickingOrder", {id, pickingOrder});
        },
        async decrementPickedAmount(
            {getters, dispatch},
            {id, index, discardedAmount}
        ) {
            let pickingOrder =
                getters.getPickingOrderById(id);
            pickingOrder.e.find((item) => item.index == index).picking.pickedAmount -=
                discardedAmount;
            return dispatch("updatePickingOrder", {id, pickingOrder});
        },
        async updatePickingOrder(
            {getters, commit},
            {id, pickingOrder}) {

            if(!getters.hasWritePermission) {
                return Promise.resolve();
            }

            return GroupPickingRepository.updatePickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder.id,
                pickingOrder
            ).then(() => {
                commit("updatePickingOrder", {id, pickingOrder});
            });
        },
        async updateSelectedPickingOrderState({dispatch, getters}, pickingOrderState) {
            const selectedPickingOrder = getters.selectedPickingOrder;
            if(!getters.hasWritePermission(selectedPickingOrder?.id)) {
                return Promise.resolve();
            }

            selectedPickingOrder.pickingOrderState = pickingOrderState;
            dispatch("updatePickingOrder", {id: selectedPickingOrder.id, pickingOrder: selectedPickingOrder})
        }
    },
    modules: {},
};
