import Repository from "@/repositories/RepositoryFactory";
const WarehouseRepository = Repository.get("warehouse");

export default {
  namespaced: true,
  state: {
    warehouses: [],
    selectedWarehouse: {},
  },
  getters: {
    getWarehouseById: (state) => (id) => {
      const warehouse =  state.warehouses.find((w) => w.id === parseInt(id));
      return warehouse;
    },
    getWarehouses(state) {
      return state.warehouses;
    },
  },
  mutations: {
    updateSelectedWarehouse(state, payload) {
      state.selectedWarehouse = payload;
    },
    setWarehouses(state, payload) {
      state.warehouses = payload;
    },
  },
  actions: {
    initWarehouses: ({ commit }) => {
      return WarehouseRepository.getWarehouses().then((data) => {
        commit("setWarehouses", data);
      });
    },
    updateSelectedWarehouse({ commit }, payload) {
      commit("updateSelectedWarehouse", payload);
    },
  },
};
