import Client from "./clients/SecureWarehouseClient";

const resource = "/warehouse";

export default {
    async createPickingOrder(warehouseId, pickingOrder) {
        if(warehouseId == null) {
            return Promise.resolve(pickingOrder);
        }
        return Client.post(`${resource}/${warehouseId}/picking`,
            pickingOrder)
            .then((response) => {
                return response.data;
            })
            .catch(() => {

            })
    },
    async getPickingOrder(warehouseId, pickingOrder) {
        if (warehouseId == null) {
            return Promise.resolve([]);
        }
        return Client.get(
            `${resource}/${warehouseId}/picking/${pickingOrder.id}`
        )
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                //return {};
            });
    },
    async previewPickingOrder(warehouseId, pickingOrder) {
      if (warehouseId == null) {
          return Promise.resolve([]);
      }
      return Client.post(
          `${resource}/${warehouseId}/picking/pickingOrderProposalPreview`,
          pickingOrder
      ).then((response) => {
          return response.data;
      }).catch(() => {
          // return {};
      });
    },
    async cancelPickingOrder(warehouseId, id) {
        if (warehouseId === null) {
            return Promise.resolve([]);
        }

        return Client.post(
            `${resource}/${warehouseId}/picking/${id}/cancel`
        )
    },
    async completePickingOrder(warehouseId, id) {
        if (warehouseId === null) {
            return Promise.resolve([]);
        }

        return Client.post(
            `${resource}/${warehouseId}/picking/${id}/complete?`
        )
    },
    async updatePickingOrder(warehouseId, id, pickingOrder) {
        if (warehouseId === null) {
            return Promise.resolve([]);
        }
        return Client.post(`${resource}/${warehouseId}/picking/${id}`, pickingOrder);
    },
    async getPickingOrderWithStockInfo(warehouseId, id) {
        if (warehouseId === null || id === null) {
            return Promise.resolve([]);
        }
        return Client.get(`${resource}/${warehouseId}/picking/${id}`);
    },
    async getPickingOrders(warehouseId, userId, orderState, sortBy, sortDirection) {
        if(warehouseId === null) {
            return Promise.resolve([]);
        }
        let query = `orderState=${orderState.join()}`;
        if (userId) {
            query += `&userId=${userId}`;
        }
        if (sortBy) {
            query += `&sortBy=${sortBy}`;
        }
        if (sortDirection) {
            query += `&sortDirection=${sortDirection}`
        }
        return Client.get(`${resource}/${warehouseId}/picking?${query}`);

    }
};
