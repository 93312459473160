import TokenService from "@/service/TokenService";
import Repository from "@/repositories/RepositoryFactory";

const UserRepository = Repository.get("user");

export default {
    namespaced: true,
    state: {
        user: {},
        acceptLanguage: "",
        userrole: {},
    },
    getters: {
        acceptLanguage(state) {
            return state.acceptLanguage;
        },
        user(state) {
            return state.user;
        },
        settings(state) {
            return state.user.settings;
        },
        defaultWarehouseId(state) {
            const warehouse = state.user.settings.find((s) => s.name === "defaultWarehouse")
                ?.value;
            return warehouse;
        },
        isLoggedIn(state) {
            if (state.user.token) {
                return true;
            } else {
                return false;
            }
        },
        isAdmin(state) {
            if (state.user.roles && state.user.roles.indexOf("ROLE_ADMIN") >= 0) {
                return true;
            } else {
                return false;
            }
        },
        findPage: (state) => (name) => {
            return state.userrole?.permissions?.find((page) => page.name === name);
        },
        hasReadPermission: (state, getters) => (name) => {
            if (getters.isAdmin) {
                return true;
            }
            let page = getters.findPage(name);
            let permission =
                page?.permission?.read === true || page?.permission?.write === true;
            return permission;
        },
        findFirstReadPermission(state, getters) {
            return state.userrole?.permissions.find((p) =>
                getters.hasReadPermission(p.name)
            );
        },
        hasWritePermission: (state, getters) => (name) => {
            if (getters.isAdmin) {
                return true;
            }
            let page = getters.findPage(name);
            let permission = page?.permission?.write === true;
            return permission;
        },
        hasReadOthersPermission: (state, getters) => (name) => {
            if(getters.isAdmin) {
                return true;
            }
            let page = getters.findPage(name);
            return page?.permission?.readOthers === true;
        },
        hasWriteOthersPermission: (state, getters) => (name) => {
            if(getters.isAdmin) {
                return true;
            }
            let page = getters.findPage(name);
            return page?.permission?.writeOthers === true;
        },
        shouldFieldBeEnabled: (state, getters) => (pageName, fieldName) => {
            if (getters.isAdmin) {
                return true;
            }
            if (getters.hasReadPermission(pageName)) {
                let page = getters.findPage(pageName);
                let field = page?.fields?.find((f) => f.key === fieldName);
                return field?.permission?.enabled;
            }
        },
    },
    mutations: {
        setLanguage(state, language) {
            state.acceptLanguage = language;
            localStorage.setItem("acceptLanguage", language);
        },
        login(state, user) {
            state.user = user;
        },
        logout(state) {
            state.user = {};
            state.userrole = {};
            localStorage.removeItem("user");
            localStorage.removeItem("userrole");
            localStorage.removeItem("groupPickingStore");
        },
        setUser(state, user) {
            state.user = user;
        },
        mergeUser(state, user) {
            state.user = Object.assign(state.user, user);
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        setRole(state, userrole) {
            state.userrole = userrole;
        },
        initialiseStore(state) {
            if (localStorage.getItem("user")) {
                state.user = JSON.parse(localStorage.getItem("user"));
            }
            if (localStorage.getItem("userrole")) {
                state.userrole = JSON.parse(localStorage.getItem("userrole"));
            }
            if (localStorage.getItem("acceptLanguage")) {
                state.language = localStorage.getItem("acceptLanguage");
            }
        },
        checkToken(state) {
            if (
                state.user &&
                state.user.token &&
                !TokenService.isJwtValid(state.user.token)
            ) {
                this.commit("auth/logout");
            }
        },
    },
    actions: {
        async login({commit, dispatch}, user) {
            localStorage.setItem("user", JSON.stringify(user));
            commit("login", user);
            await dispatch("initialiseStore", null, {root: true});
        },
        async logout({commit}) {
            localStorage.removeItem("user");
            localStorage.removeItem("userrole");
            localStorage.removeItem("groupPickingStore");
            commit("setUser", {});
            commit("setRole", {});
            commit("groupPicking/replaceStore",
                {
                    pickingOrders: [],
                    pickingOrderProposals: [],
                    selectedWarehouse: []
                }, {root: true});

        },
        async changePassword({state}, changePassword) {
            return UserRepository.changePassword(state.user.id, changePassword);
        },
        async initialiseStore({commit, state}) {
            commit("initialiseStore");
            if (state.user.id) {
                return Promise.all([
                    UserRepository.getUserRoleOfUser(state.user.id).then((data) => {
                        localStorage.setItem("userrole", JSON.stringify(data));
                        commit("setRole", data);
                    }),
                    UserRepository.getUserById(state.user.id).then((data) => {
                        commit("mergeUser", data);
                    }),
                ]);
            } else {
                throw new Error("user not logged in");
            }
        },
    },
    modules: {},
};
